var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios, { AxiosError } from 'axios';
// Replace 'textrepository' with the service name defined in your docker-compose.yml
//const API_BASE_URL = process.env.NODE_ENV === 'development' 
//    ? 'http://localhost:3001'
//    : 'https://api.orbscape.io';
const API_BASE_URL = 'https://api.orbscape.io';
//todo refactor below functions
export function fetchEntries() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/functions`); //, {
            console.log('Full response:', response); // Log the entire response object
            console.log('Response data:', response.data); // Log response data separately
            return response.data;
            // Handle the response
        }
        catch (error) {
            if (error instanceof AxiosError) {
                console.error('Error message:', error.message);
                console.error('Error response:', (_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
                console.error('Error status:', (_b = error.response) === null || _b === void 0 ? void 0 : _b.status);
                console.error('Error config:', error.config);
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
}
export function getGithubRepos() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/systemrepositories`); //, {
            console.log('Full response:', response); // Log the entire response object
            console.log('Response data:', response.data); // Log response data separately
            return response.data;
            // Handle the response
        }
        catch (error) {
            if (error instanceof AxiosError) {
                console.error('Error message:', error.message);
                console.error('Error response:', (_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
                console.error('Error status:', (_b = error.response) === null || _b === void 0 ? void 0 : _b.status);
                console.error('Error config:', error.config);
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
}
export function getDockerContainers() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/dockercontainers`); //, {
            console.log('Full response:', response); // Log the entire response object
            console.log('Response data:', response.data); // Log response data separately
            return response.data;
            // Handle the response
        }
        catch (error) {
            if (error instanceof AxiosError) {
                console.error('Error message:', error.message);
                console.error('Error response:', (_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
                console.error('Error status:', (_b = error.response) === null || _b === void 0 ? void 0 : _b.status);
                console.error('Error config:', error.config);
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
}
export function getKubernetesInfo() {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/kubernetesinfo`); //, {
            console.log('Full response:', response); // Log the entire response object
            console.log('Response data:', response.data); // Log response data separately
            return response.data;
            // Handle the response
        }
        catch (error) {
            if (error instanceof AxiosError) {
                console.error('Error message:', error.message);
                console.error('Error response:', (_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
                console.error('Error status:', (_b = error.response) === null || _b === void 0 ? void 0 : _b.status);
                console.error('Error config:', error.config);
            }
            else {
                console.error('Unexpected error:', error);
            }
        }
    });
}
//Old
// Example function to get all text entries
export function getAllTextEntries() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${API_BASE_URL}/GetTextStore`);
            return response.data;
        }
        catch (error) {
            console.error('Error fetching text entries:', error);
            throw error;
        }
    });
}
// Example function to add text
export function addText(text) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.post(`${API_BASE_URL}/AddTextStore`, { text });
            return response.data;
        }
        catch (error) {
            console.error('Error adding text:', error);
            throw error;
        }
    });
}
// Example function to update text
export function updateText(id, text) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.put(`${API_BASE_URL}/UpdateTextStore/${id}`, { text });
            return response.data;
        }
        catch (error) {
            console.error('Error updating text:', error);
            throw error;
        }
    });
}
// Example function to delete text
export function deleteText(id) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.delete(`${API_BASE_URL}/DeleteTextStore/${id}`);
            return response.data;
        }
        catch (error) {
            console.error('Error deleting text:', error);
            throw error;
        }
    });
}
